import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { Container } from "reactstrap"
import SEO from "../components/seo"
import marked from "marked"
import Swiper from "react-id-swiper"
import { Pagination, Navigation } from "swiper/dist/js/swiper.esm"
import "swiper/dist/css/swiper.css"
import "./project-template.css"
const slugify = require("slugify")

class ProjectTemplate extends React.Component {
	render() {
		const { post } = this.props.data
		const { previous, next } = this.props.pageContext
		const params = {
			modules: [Pagination, Navigation],
			slidesPerView: 1,
			spaceBetween: 0,
			containerClass: "swiper-container pb-3",
			nested: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			thumbs: {
				swiper: {
					el: ".gallery-thumbs",
					slidesPerView: 8,
					spaceBetween: 15,
					breakpoints: {
						991: {
							slidesPerView: 5,
						},
						812: {
							slidesPerView: 4,
						},
					},
				},
			},
			// breakpoints: {
			//   768: {
			//     slidesPerView: 3,
			//     spaceBetween: 30
			//   },
			//   640: {
			//     slidesPerView: 2,
			//     spaceBetween: 20
			//   },
			//   320: {
			//     slidesPerView: 1,
			//     spaceBetween: 10
			//   }
			// }
		}
		return (
			<Layout>
				<SEO title={post.title} description={post.excerpt} />
				<div class="page-headline">
					<div class="container">
						<div class="section-heading text-center">
							<h6 class="font-weight-bold text-uppercase text-white-50 flair">
								Project
							</h6>
							<h1>
								<strong>{post.title}</strong>
							</h1>
						</div>
					</div>
				</div>
				<div className="page-content">
					<Container>
						<div class="row justify-content-center projects-body">
							<div class="col-md-9">
								<div className="row mb-5">
									<div className="col-md-12">
										<div className="project-gallery">
											<Swiper {...params}>
												{post.imageGallery.map(item => (
													<div
														className="swiper-slide"
														style={{
															backgroundImage: `url(${item.asset.url})`,
														}}
													/>
												))}
											</Swiper>
										</div>
									</div>
									<div className="col-md-12 d-none d-md-block">
										<div className="swiper-container gallery-thumbs">
											<div className="swiper-wrapper">
												{post.imageGallery.map(item => (
													<div
														className="swiper-slide"
														style={{
															backgroundImage: `url(${item.asset.fixed.src})`,
														}}
													/>
												))}
											</div>
										</div>
									</div>
								</div>
								<h2>{post.subheading}</h2>
								{post.body !== null ? (
									<div
										dangerouslySetInnerHTML={{
											__html: marked(post.body),
										}}
									/>
								) : (
									""
								)}
							</div>
						</div>
						<div class="blog-nav">
							<div class="row">
								<div class="col-md-6 text-md-left">
									{previous ? (
										<div>
											<span class="font-weight-bold text-uppercase text-muted d-block small">
												Previous
											</span>
											<Link
												to={`portfolio-item/${slugify(
													previous.title.toLowerCase()
												)}`}
											>
												{previous.title}
											</Link>
										</div>
									) : null}
								</div>
								<div class="col-md-6 text-md-right">
									{next ? (
										<div>
											<span class="font-weight-bold text-uppercase text-muted d-block small">
												next
											</span>
											<Link
												to={`portfolio-item/${slugify(
													next.title.toLowerCase()
												)}`}
											>
												{next.title}
											</Link>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</Container>
				</div>
			</Layout>
		)
	}
}

export default ProjectTemplate

export const projectPostQuery = graphql`
	query ProjectPostTemplateQuery($id: String!) {
		post: sanityProject(id: { eq: $id }) {
			id
			title
			subheading
			body
			imageGallery {
				alt
				asset {
					url
					fluid(maxWidth: 1140, toFormat: JPG) {
						src
						srcSet
					}
					fixed(width: 100, height: 100, toFormat: JPG) {
						src
					}
				}
			}
		}
	}
`
